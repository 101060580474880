import Vue from "vue";
import File from "@/api/file.js";
import Bus from "@/components/Bus/bus.js";
import router from "@/router";
import axios from "axios";

const chatWithAi = async (model, prompt, onUpdate, onFinish, onError) => {
  try {
    const response = await fetch("https://goapi.gptnb.ai/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer sk-3EFwiRmN1z09r7giB8987227104e4452Ba3f9f53F7EbAeDf`,
      },
      body: JSON.stringify({
        model: model.name,
        messages: [{ role: "user", content: prompt }],
        stream: true,
      }),
    });

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      const lines = chunk.split("\n");

      for (const line of lines) {
        if (line.startsWith("data: ")) {
          const data = line.slice(6);
          if (data === "[DONE]") {
            if (onFinish) onFinish();
            break;
          }
          try {
            const parsed = JSON.parse(data);
            if (
              parsed.choices &&
              parsed.choices[0].delta &&
              parsed.choices[0].delta.content
            ) {
              if (onUpdate) onUpdate(parsed.choices[0].delta.content);
            }
          } catch (e) {
            console.error("Error parsing JSON:", e);
            if (onError) onError(e);
          }
        }
      }
    }
  } catch (error) {
    if (error.name === "AbortError") {
      console.log("Fetch aborted");
    } else {
      console.error("Streaming failed:", error);
      if (onError) onError(error);
    }
  } finally {
  }
};

export default {
  chatWithAi,
};
